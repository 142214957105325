import { getMultiCall, getWeb3Contract } from '../helper/contractHelper';
import { PRESALE_ADDRESS } from '../helper/constant';
import { useEffect, useState } from 'react';
import presaleAbi from '../abis/presale.json';
import { toast } from 'react-toastify';



export const useCommonStats = (updator) => {
    
    const [stats, setStats] = useState({
        rate : 0,
        saleStatus : false,
        claimStatus : false,
        levelRate : 0,
        loading : true
    });

    let presaleContract = getWeb3Contract(presaleAbi, PRESALE_ADDRESS);
    useEffect(() => {
        const fetch = async () => {
            try {
                setStats({
                    ...stats,
                    loading : true
                })
                
                let data = await getMultiCall([
                    presaleContract.methods.rate(), //0
                    presaleContract.methods.saleStatus(), //1
                    presaleContract.methods.claimStatus(), //2
                    presaleContract.methods.levelRate(0), //3
                ])
                

                setStats({
                    rate : data[0] / Math.pow(10,18),
                    saleStatus : data[1],
                    claimStatus : data[2],
                    levelRate : data[3],
                    loading : false
                })
            }
            catch (err) {
                toast.error(err.reason ? err.reason : err.message);
            }
        }


        fetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updator])

    return stats;
}