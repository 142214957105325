import React from 'react';

export default function Section5() {
    return (
        <section className="team padding-t-60 padding-b-120" style={{ fontFamily: 'outfit' }} id="team">
            <div className="container container-two">
                <div className="section-heading" style={{ position: 'relative' }}>
                    <span className="section-heading__subtitle">
                        <span className="text-gradient fw-semibold">TEAM</span>
                    </span>
                    <h2 className="section-heading__title">Real Asset Capitals</h2>
                    <p style={{ textAlign: 'center' }}>The governance token that gives you unique access to investments in the physical world.</p>
                </div>
                <div className="row gy-4">
                    <div className="col-lg-4 col-sm-6 col-xs-6">
                        <div className="team-item">
                            <div className="team-item__thumb">
                                <img src="assets/images/team/1.png" alt="" className="cover-img" />
                            </div>
                            <div className="team-item__content flx-between flex-nowrap">
                                <div>
                                    <h6 className="team-item__name mb-1 text-white">Bernardo Maciel</h6>
                                    <span className="team-item__designation font-12 text-white fw-light">CEO of Real Asset Capitals</span>
                                </div>
                                <div className="social-share">
                                    <button type="button" className="social-share__button"><i className="fas fa-share-alt"></i></button>
                                    <ul className="social-share-list">
                                        <li className="social-share-list__item">
                                            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/bernardomaciel/" className="social-share-list__link"><i className="fab fa-linkedin-in"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-xs-6">
                        <div className="team-item">
                            <div className="team-item__thumb">
                                <img src="assets/images/team/2.png" alt="" className="cover-img" />
                            </div>
                            <div className="team-item__content flx-between flex-nowrap">
                                <div>
                                    <h6 className="team-item__name mb-1 text-white">Duarte Guerreiro</h6>
                                    <span className="team-item__designation font-12 text-white fw-light">Advisor and spokesperson</span>
                                </div>
                                <div className="social-share">
                                    <button type="button" className="social-share__button"><i className="fas fa-share-alt"></i></button>
                                    <ul className="social-share-list">
                                        <li className="social-share-list__item">
                                            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/duarte-guerreiro-a4852510b" className="social-share-list__link"><i className="fab fa-linkedin-in"></i></a>
                                        </li>
                                        <li className="social-share-list__item">
                                            <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/duartempg.eth/" className="social-share-list__link"><i className="fab fa-instagram"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-xs-6">
                        <div className="team-item">
                            <div className="team-item__thumb">
                                <img src="assets/images/team/3.png" alt="" className="cover-img" />
                            </div>
                            <div className="team-item__content flx-between flex-nowrap">
                                <div>
                                    <h6 className="team-item__name mb-1 text-white">Danilo Guerreiro</h6>
                                    <span className="team-item__designation font-12 text-white fw-light">Advisor</span>
                                </div>
                                <div className="social-share">
                                    <button type="button" className="social-share__button"><i className="fas fa-share-alt"></i></button>
                                    <ul className="social-share-list">
                                        <li className="social-share-list__item">
                                            <a href="https://www.linkedin.com" className="social-share-list__link"><i className="fab fa-linkedin-in"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-xs-6">
                        <div className="team-item">
                            <div className="team-item__thumb">
                                <img src="assets/images/team/4.png" alt="" className="cover-img" />
                            </div>
                            <div className="team-item__content flx-between flex-nowrap">
                                <div>
                                    <h6 className="team-item__name mb-1 text-white">Miguel Correia</h6>
                                    <span className="team-item__designation font-12 text-white fw-light">External Dev/Marketing (Lay3r)</span>
                                </div>
                                <div className="social-share">
                                    <button type="button" className="social-share__button"><i className="fas fa-share-alt"></i></button>
                                    <ul className="social-share-list">
                                        <li className="social-share-list__item">
                                            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/bmccorreia/" className="social-share-list__link"><i className="fab fa-linkedin-in"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
