import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Web3ModalProvider } from './WalletProvider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Web3ModalProvider>
      <ToastContainer />
      <App />
    </Web3ModalProvider>
  </React.StrictMode>
);
