import React from 'react';

export default function Section2() {
  return (
    <section className="working-process padding-b-60" style={{ marginTop: '20%', fontFamily: 'outfit' }}>

      <div className="img_ourmission">
        <img id="img_ourmission" src="assets/images/shapes/work-process-vector.png"
          style={{ zIndex: 0, width: '49%', position: 'absolute', top: '-1%', right: '51%', animation: 'rotationScale 5s linear infinite' }}
          className="" alt="Work Process Vector" />
      </div>

      <div className="section-heading" id="mission">
        <span className="section-heading__subtitle bg-white">
          <span className="text-gradient fw-semibold">OUR MISSION</span>
        </span>
        <h2 className="section-heading__title" style={{ fontSize: '35px' }}>Transform Into Digital</h2>
      </div>

      <div className="container container-two">
        <div className="row gy-4">
          <div className="col-lg-6 pe-lg-5 d-lg-block">
            <div className="working-process__thumb rounded overflow-hidden">
              <video poster="assets/images/thumbs/video.png"
                style={{ borderStyle: 'double', borderColor: '#987646', opacity: '0.95', borderRadius: '15px' }}
                width="100%" height="100%" controls>
                <source src="assets/images/video/mission.mp4" type="video/mp4" />
              </video>
            </div>
          </div>

          <div className="col-lg-6" style={{ margin: 'auto' }}>

            <p className="section-heading__desc" style={{ marginTop: '1px', textAlign: 'justify' }}>
              Real Asset Capitals is a company dedicated to providing real asset investment solutions, with the aim
              of generating sustainable and robust returns for its clients. Our mission is to identify,
              acquire and manage tangible assets, such as real estate, infrastructure and natural
              resources, that offer stability and long-term growth!
            </p>

            <div style={{ marginTop: '20px', display: 'inline-flex' }}>

              <div style={{ width: '20%' }}>
                <img src="assets/images/team/1.png" style={{ width: '100%', borderRadius: '15px' }} alt="Team Member" />
              </div>

              <div style={{ width: '80%', margin: 'auto', marginLeft: '20px' }}>
                <ul className="check-list">
                  <li className="check-list__item d-flex align-items-center">
                    <span className="icon"><i className="fas fa-check"></i></span>
                    <span className="text fw-normal text-body font-16">
                      Team with years of experience in real assets
                    </span>
                  </li>

                  <li className="check-list__item d-flex align-items-center">
                    <span className="icon"><i className="fas fa-check"></i></span>
                    <span className="text fw-normal text-body font-16">Transparency in development</span>
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="container container-two img_ourmission" style={{ marginTop: '25px' }}>
        <div className="counter-five-wrapper">
          <div className="counter-five-item style-two d-flex align-items-center" style={{ opacity: '0' }}>
            <span className="counter-five-item__icon">
              <img src="assets/images/icons/counter-five-icon1.svg" alt="Team Members" />
            </span>
            <div className="counter-five-item__content">
              <h2 className="counter-five-item__number text-heading is-visible" style={{ visibility: 'visible' }}>
                200+
              </h2>
              <span className="counter-five-item__text text-body">Team member</span>
            </div>
          </div>
          <div className="counter-five-item style-two d-flex align-items-center">
            <span className="counter-five-item__icon">
              <img src="assets/images/partners/burguer.png" style={{ width: '50%', opacity: '0.8' }} alt="Franchise Partner" />
            </span>
            <div className="counter-five-item__content">
              <span className="counter-five-item__text text-body" style={{ fontSize: '12px' }}>FRANCHISE PARTNER</span>
            </div>
          </div>
          <div className="counter-five-item style-two d-flex align-items-center">
            <span className="counter-five-item__icon">
              <img src="assets/images/partners/mcdonads.png" style={{ width: '50%', opacity: '0.8' }} alt="Franchise Partner" />
            </span>
            <div className="counter-five-item__content">
              <span className="counter-five-item__text text-body" style={{ fontSize: '12px' }}>FRANCHISE PARTNER</span>
            </div>
          </div>
          <div className="counter-five-item style-two d-flex align-items-center">
            <span className="counter-five-item__icon">
              <img src="assets/images/partners/remax.png" style={{ width: '50%', opacity: '0.8' }} alt="Strategic Partner" />
            </span>
            <div className="counter-five-item__content">
              <span className="counter-five-item__text text-body" style={{ fontSize: '12px' }}>STRATEGIC PARTNER</span>
            </div>
          </div>
        </div>
      </div>

    </section>
  );
}
