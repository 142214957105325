import { bsc } from "wagmi/chains";
import bnbImg from '../images/binance-coin.png';
import usdtImg from '../images/usdt.png';

export const DEFAULT_RPC = process.env.REACT_APP_DEFAULT_RPC;
export const DEFAULT_CHAIN = process.env.REACT_APP_DEFAULT_CHAIN;
export const MULTICALL = process.env.REACT_APP_MULTICALL;
export const TOKEN_ADDRESS = process.env.REACT_APP_TOKEN_ADDRESS;
export const EXPLORE_URL = process.env.REACT_APP_EXPLORE_URL;
export const TOKEN_SYMBOL = process.env.REACT_APP_TOKEN_SYMBOL;
export const TOKEN_DECIMALS = process.env.REACT_APP_TOKEN_DECIMALS;
export const CURRENCY_SYMBOL = process.env.REACT_APP_CURRENCY_SYMBOL;
export const PRESALE_ADDRESS = process.env.REACT_APP_PRESALE_ADDRESS;
export const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;
export const chains = [bsc]
export const BASE_URL = "https://babyliger.vip"


export const BUY_CURRENCY = [
    {
        address : "0x0000000000000000000000000000000000000000",
        name : "Binanace Coin",
        symbol : "BNB",
        image : bnbImg,
        decimals : 18
    },
    {
        address : "0x55d398326f99059fF775485246999027B3197955",
        name : "Tether USD",
        symbol : "USDT",
        image : usdtImg,
        decimals : 18
    }
]