import React from 'react';

const Footer = () => {
  return (
    <footer className="footer padding-y-120" style={{ fontFamily: 'outfit' }} id="contact">
      <div className="container container-two">
        <div className="row gy-5">
          <div className="col-xl-3 col-sm-6 col-xsm-6">
            <div className="footer-item">
              <div className="footer-item__logo">
                <a href="index.html"> <img src="assets/images/logo/white-logo.png" alt="" /></a>
              </div>
              <p className="footer-item__desc" style={{ textAlign: 'justify' }}>Revolutionizing the Real Estate Market through Tokenization, Transforming Properties into Digital Investment Opportunities!</p>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-xsm-6">
            <div className="footer-item ms-xl-5">
              <h6 className="footer-item__title">Useful Links</h6>
              <ul className="footer-menu">
                <li className="footer-menu__item"><a href="https://x.com/realasset_cap" target="_blank" className="footer-menu__link">x.com</a></li>
                <li className="footer-menu__item"><a target="_blank" href="https://www.twitch.tv/real_asset_capitals" className="footer-menu__link">Twitch</a></li>
                <li className="footer-menu__item"><a target="_blank" href="https://www.youtube.com/@realasset_cap" className="footer-menu__link">Youtube</a></li>
                <li className="footer-menu__item"><a target="_blank" href="https://t.me/realasset_cap_chat" className="footer-menu__link">Telegram</a></li>
                <li className="footer-menu__item"><a target="_blank" href="https://coinmarketcap.com/community/profile/realassetcapitals/" className="footer-menu__link">CMC Community</a></li>
              </ul>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-xsm-6">
            <div className="footer-item">
              <h6 className="footer-item__title">Documentation</h6>
              <ul className="footer-menu">
                <li className="footer-menu__item"><a href="" className="footer-menu__link">Legal Status (soon)</a></li>
                <li className="footer-menu__item"><a target="_blank" href="https://real-asset-capitals.gitbook.io/whitepaper-or-real-asset-capitals/" className="footer-menu__link">Whitepaper</a></li>
                <li className="footer-menu__item"><a href="" className="footer-menu__link">Privacy Policy (soon)</a></li>
                <li className="footer-menu__item"><a href="property.html" className="footer-menu__link">Token Smart-Contract (soon)</a></li>
                <li className="footer-menu__item"><a href="property.html" className="footer-menu__link">Tutorials RPC Platform (soon)</a></li>
              </ul>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-xsm-6">
            <div className="footer-item">
              <h6 className="footer-item__title">Strategic Partners</h6>
              <div className="gallery-list">
                <div className="gallery-list__item">
                  <img src="assets/images/partners/remax1.png" alt="" />
                </div>
                <div className="gallery-list__item">
                  <img src="assets/images/partners/zome1.png" alt="" />
                </div>
                <div className="gallery-list__item">
                  <img src="assets/images/partners/cent1.png" alt="" />
                </div>
                <div className="gallery-list__item">
                  <img src="assets/images/partners/era1.png" alt="" />
                </div>
                <div className="gallery-list__item">
                  <img src="assets/images/partners/mcdonads1.png" alt="" />
                </div>
                <div className="gallery-list__item">
                  <img src="assets/images/partners/burguer1.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-footer" style={{ fontFamily: 'outfit' }}>
        <div className="container container-two">
          <div className="bottom-footer__inner flx-between gap-3">
            <p className="bottom-footer__text"> &copy; Real Asset Capitals 2024 | All Rights Reserved </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
