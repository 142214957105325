import React from 'react'

export default function Header() {
  return (
    <header
      className="header menu_header headmob"
      style={{
        height: '55px',
        zIndex: 5,
        position: 'sticky',
        width: '665px',
        borderRadius: '0px 0px 15px 0px',
      }}
    >
      <div className="container container-two flxaling">
        <nav
          className="header-inner flx-between"
          style={{
            marginLeft: '15px',
            display: 'inline-flex',
          }}
        >
          <div
            className="header-menu d-lg-block d-none"
            style={{ fontFamily: 'Outfit' }}
          >
            <ul
              className="nav-menu flx-align"
              style={{
                marginLeft: '50px',
                marginTop: '-15px',
              }}
            >
              <li className="nav-menu__item">
                <a href="#mission" className="nav-menu__link">
                  Mission
                </a>
              </li>
              <li className="nav-menu__item">
                <a href="#partners" className="nav-menu__link">
                  Partners
                </a>
              </li>
              <li className="nav-menu__item">
                <a href="#token" className="nav-menu__link">
                  Token
                </a>
              </li>
              <li className="nav-menu__item has-submenu">
                <a href="javascript:void(0)" className="nav-menu__link">
                  RCP Platform
                </a>
                <ul className="nav-submenu">
                  <li className="nav-submenu__item">
                    <a href="#" className="nav-submenu__link">
                      Documentation
                    </a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="#" className="nav-submenu__link">
                      Login
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-menu__item">
                <a href="#team" className="nav-menu__link">
                  Team
                </a>
              </li>
              <li className="nav-menu__item">
                <a href="#contact" className="nav-menu__link">
                  Contact
                </a>
              </li>
              <li className="social-list__item">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'hsl(0deg 0% 0%)' }}
                  href="https://t.me/realasset_cap_chat"
                  className="social-list__link flx-center"
                >
                  <i className="fab fa-telegram"></i>
                </a>
              </li>
              <li className="social-list__item">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'hsl(0deg 0% 0%)' }}
                  href="https://x.com/realasset_cap"
                  className="social-list__link flx-center"
                >
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li className="social-list__item">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'hsl(0deg 0% 0%)' }}
                  href="https://www.twitch.tv/real_asset_capitals"
                  className="social-list__link flx-center"
                >
                  <i className="fab fa-twitch"></i>
                </a>
              </li>
            </ul>
          </div>

          <div className="socialinitial" style={{ display: 'none' }}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'hsl(0deg 0% 0%)' }}
              href="https://t.me/realasset_cap_chat"
              className="social-list__link flx-center"
            >
              <i className="fab fa-telegram"></i>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'hsl(0deg 0% 0%)', marginLeft: '10px' }}
              href="https://x.com/realasset_cap"
              className="social-list__link flx-center"
            >
              <i className="fab fa-twitter"></i>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'hsl(0deg 0% 0%)', marginLeft: '10px' }}
              href="https://www.twitch.tv/real_asset_capitals"
              className="social-list__link flx-center"
            >
              <i className="fab fa-twitch"></i>
            </a>
          </div>

          <div className="header-right flx-align" style={{ fontFamily: 'Outfit' }}>
            <button type="button" className="toggle-mobileMenu d-lg-none ms-3" style={{ textAlign: 'right' }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="24"
                viewBox="0 0 30 24"
                fill="none"
              >
                <line
                  x1="0.0078125"
                  y1="12.293"
                  x2="30.0078"
                  y2="12.293"
                  stroke="#181616"
                  strokeWidth="3"
                ></line>
                <path
                  d="M5.00781 22.293H30.0078"
                  stroke="#181616"
                  strokeWidth="3"
                ></path>
                <path
                  d="M10.0078 2.29297H30.0078"
                  stroke="#181616"
                  strokeWidth="3"
                ></path>
              </svg>
              <p style={{ display: 'contents' }}></p>
            </button>
          </div>
        </nav>
      </div>
    </header>
  )
}
