import React, { useEffect, useRef, useState } from 'react'
import PreLoader from '../components/PreLoader'
import MobileMenu from '../components/MobileMenu'
import Header from '../components/Header'
import Section1 from '../components/Section1'
import Section2 from '../components/Section2'
import Section3 from '../components/Section3'
import Section4 from '../components/Section4'
import Section5 from '../components/Section5'
import Footer from '../components/Footer'
import BuyModal from '../components/BuyModal'

export default function Home() {
    const count = useRef(0);
    const [show,setShow] = useState(false);

    useEffect(() => {

        if (count.current === 0) {
            count.current += 1;
            // Load main.js
            const mainJsScript = document.createElement('script');
            mainJsScript.src = `/assets/js/main.js`;
            document.body.appendChild(mainJsScript);

            // Load Botpress Webchat inject.js
            const botpressScript = document.createElement('script');
            botpressScript.src = 'https://cdn.botpress.cloud/webchat/v1/inject.js';
            document.body.appendChild(botpressScript);

            // Load scriptloader.js
            const scriptLoaderScript = document.createElement('script');
            scriptLoaderScript.src = `assets/js/scriptloader.js`;
            document.body.appendChild(scriptLoaderScript);


            return () => {
                // Clean up: Remove scripts on component unmount
                document.body.removeChild(mainJsScript);
                document.body.removeChild(botpressScript);
                document.body.removeChild(scriptLoaderScript);
            };
        }
    }, []); // Empty dependency array ensures this effect runs only once after mount


    return (
        <>
            <PreLoader />
            <MobileMenu />
            <main className="body-bg">
                <div id="slider" className="img_ourmission">
                    <img
                        className="selected"
                        src="assets/images/thumbs/banner-img.png"
                        alt="Image1"
                        style={{
                            zIndex: 2,
                            width: '56%',
                            position: 'absolute',
                            top: '5%',
                            right: '0%',
                            animation: 'rotationScale 5s linear infinite',
                        }}
                    />
                    <img
                        src="assets/images/thumbs/banner-img1.png"
                        alt="Image2"
                        style={{
                            zIndex: 2,
                            width: '56%',
                            position: 'absolute',
                            top: '5%',
                            right: '0%',
                            animation: 'rotationScale 5s linear infinite',
                        }}
                    />
                    <img
                        src="assets/images/thumbs/banner-img2.png"
                        alt="Image3"
                        style={{
                            zIndex: 2,
                            width: '56%',
                            position: 'absolute',
                            top: '5%',
                            right: '0%',
                            animation: 'rotationScale 5s linear infinite',
                        }}
                    />
                    <img
                        src="assets/images/thumbs/banner-img3.png"
                        alt="Image4"
                        style={{
                            zIndex: 2,
                            width: '56%',
                            position: 'absolute',
                            top: '5%',
                            right: '0%',
                            animation: 'rotationScale 5s linear infinite',
                        }}
                    />
                </div>
                <Header />
                <video
                    autoPlay
                    muted
                    loop
                    style={{
                        objectFit: 'cover',
                        width: '100%',
                        height: '100vh',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        filter: 'opacity(14%) grayscale(100%)',
                    }}
                >
                    <source src="assets/images/video/background.mp4" type="video/mp4" />
                </video>
                <Section1  setShow={setShow}/>
                <Section2 />
                <Section3 />
                <Section4 />
                <Section5 />
                <Footer/>
                <BuyModal show={show} setShow={setShow} />

            </main>
        </>
    )
}
