import React from 'react';

const Section4 = () => {
    return (
        <section className="faq-two padding-y-120" style={{ fontFamily: 'outfit' }} id="token">
            <div className="img_ourmission">
                <img src="assets/images/shapes/work-process-vector1.png" style={{ position: 'absolute', zIndex: 0 }} />
            </div>

            <div className="container container-two">
                <div className="section-heading" style={{ position: 'relative' }}>
                    <span className="section-heading__subtitle">
                        <span className="text-gradient fw-semibold">TOKEN</span>
                    </span>
                    <h2 className="section-heading__title">Token of Real Asset Capitals</h2>
                    <p style={{ textAlign: 'center' }}>The governance token that gives you unique access to investments in the physical world.</p>
                </div>
                <div className="row gy-4">
                    <div className="col-lg-6" style={{ position: 'relative', margin: 'auto' }}>
                        <div className="common-accordion style-two accordion" id="accordionExample" style={{ zIndex: 2 }}>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button" style={{ color: '#987646' }} type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false"
                                        aria-controls="collapseOne">
                                        What is the use of the RAC token?
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                    <div className="accordion-body" style={{ paddingTop: '0px' }}>
                                        <p className="accordion-body__desc font-18" style={{ textAlign: 'justify', fontSize: '15px!important' }}>
                                            The RAC token is essential for the economic management of the Real Asset Capitals project, performing two main functions: it allows transactions (trading) and unlocks access to physical investments offered by the company.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" style={{ color: '#987646' }} type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
                                        aria-controls="collapseTwo">
                                        Does the RAC token have a transfer/buy/sell fee?
                                    </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div className="accordion-body" style={{ paddingTop: '0px' }}>
                                        <p className="accordion-body__desc font-18" style={{ textAlign: 'justify', fontSize: '15px!important' }}>
                                            The RAC token is completely tax-free, allowing users to carry out transactions and use its features at no additional cost. This makes it easier to use and maximizes the benefits for token holders.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" style={{ color: '#987646' }} type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false"
                                        aria-controls="collapseFour">
                                        Will the token be used to invest in real assets?
                                    </button>
                                </h2>
                                <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div className="accordion-body" style={{ paddingTop: '0px' }}>
                                        <p className="accordion-body__desc font-18" style={{ textAlign: 'justify', fontSize: '15px!important' }}>
                                            RAC tokens are not used directly to invest in real assets, but rather to unlock access to these physical investments through fractions. There are different levels (TIERS) of access, determined by the number of tokens purchased.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item" style={{ marginBottom: '15px' }}>
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" style={{ color: '#987646' }} type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false"
                                        aria-controls="collapseFive">
                                        Is the contract audited?
                                    </button>
                                </h2>
                                <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div className="accordion-body" style={{ paddingTop: '0px' }}>
                                        <p className="accordion-body__desc font-18" style={{ textAlign: 'justify', fontSize: '15px!important' }}>
                                            The RAC token underwent a rigorous audit and passed all security tests, ensuring maximum protection and reliability for its users. This approval confirms the integrity and security of the token, providing investors with peace of mind.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 ps-lg-4 d-lg-block" style={{ margin: 'auto' }}>
                        <div className="faq-two-thumb">
                            <video poster="assets/images/thumbs/video1.png" style={{ borderStyle: 'double', borderColor: '#987646', opacity: 0.95, borderRadius: '15px' }} width="100%" height="100%" controls>
                                <source src="assets/images/video/token.mp4" type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Section4;
