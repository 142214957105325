import { useAccount } from 'wagmi';
import { useWeb3Modal } from '@web3modal/react';
import { trimAddress } from "../helper/functions";


export const ConnectButton = function ({className}) {
  const { open } = useWeb3Modal();
  const { address, isDisconnected } = useAccount();

  return (
    <>
      {address && !isDisconnected ? (
        <button className={className} onClick={() => open()}>
          {trimAddress(address)}
        </button>
      ) : (
        <button className={className} onClick={() => open()}>
          Connect
        </button>
      )}
    </>
  );
};

export default ConnectButton;