import React, { useEffect, useMemo, useRef, useState } from 'react';
import ConnectButton from './ConnectButton';
import { useAccount, useNetwork } from 'wagmi';
import { useCommonStats } from '../hooks/useCommon';
import { formatPrice, getWeb3 } from '../helper/functions';
import { BUY_CURRENCY, DEFAULT_CHAIN, PRESALE_ADDRESS } from '../helper/constant';
import { getContract, getTokenAmount } from '../helper/contractHelper';
import { useLocation } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useAccountStats } from '../hooks/useAccount';
import { toast } from 'react-toastify';
import tokenAbi from '../abis/token.json';
import presaleAbi from '../abis/presale.json';
import { useEthersSigner } from "../hooks/useEthersProvider";
import { ethers } from 'ethers';

const BuyModal = ({ show, setShow }) => {
  const { address } = useAccount();
  const { chain } = useNetwork();
  const [updator, setUpdator] = useState(1);
  const [loading, setLoading] = useState(false);
  const stats = useCommonStats(updator);
  const [amount, setAmount] = useState('')
  const [selectedId, setSelectedId] = useState(0);
  const [usdValue, setUsdValue] = useState(0);
  const [tokenAmount, setTokenAmount] = useState();
  const timeoutRef = useRef(null);
  const search = useLocation().search;
  const [refcopy, setRefcopy] = useState(false);
  const [refAddress, setRefAddress] = useState('');
  const accStats = useAccountStats(updator, selectedId)
  const signer = useEthersSigner();
  const [cloading, setCloading] = useState(false);
  const selectedData = useMemo(() => {
    return BUY_CURRENCY && BUY_CURRENCY[selectedId] ? BUY_CURRENCY[selectedId] : {};
}, [selectedId]);
  


  useEffect(() => {
    const queryChainId = new URLSearchParams(search).get('ref');
    if (queryChainId !== '') {
      setRefAddress(queryChainId);
    }

    // eslint-disable-next-line
  }, [])


  useEffect(() => {
    async function fetch() {
      let resAmount = BUY_CURRENCY && BUY_CURRENCY[selectedId] ? await getTokenAmount(BUY_CURRENCY[selectedId].address, amount, BUY_CURRENCY[selectedId].decimals) : 0
      setTokenAmount(resAmount[0])
      setUsdValue(resAmount[1])
    }
    fetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId])


  const handleChangeAmount = async (e) => {
    let value = e.target.value;
    const patt = /^\d+\.{0,1}\d{0,6}$/;
    if (value === '' || value === '0') {
      setAmount(value);
      setTokenAmount(0)
    }
    else if (patt.test(value)) {
      setAmount(value);
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }


    timeoutRef.current = setTimeout(async () => {
      if (patt.test(value)) {
        let resAmount = BUY_CURRENCY && BUY_CURRENCY[selectedId] ? await getTokenAmount(BUY_CURRENCY[selectedId].address, value, BUY_CURRENCY[selectedId].decimals) : 0
        setTokenAmount(resAmount[0])
        setUsdValue(resAmount[1])
      }
    }, 1000);

  };

  const handleApprove = async () => {
    if (address) {
      if (chain && parseInt(chain.id) === parseInt(DEFAULT_CHAIN)) {
        try {

          setLoading(true);

          let tokenContract = getContract(tokenAbi, BUY_CURRENCY[selectedId].address, signer);
          let amount1 = ethers.utils.parseEther('1000000000000').toString();

          let tx = await tokenContract.approve(PRESALE_ADDRESS, amount1, { 'from': address });
          toast.loading('Waiting for confirmation')

          var interval = setInterval(async function () {
            let web3 = getWeb3();
            var response = await web3.eth.getTransactionReceipt(tx.hash);
            if (response != null) {
              clearInterval(interval)
              if (response.status === true) {
                toast.dismiss()
                toast.success('success ! your last transaction is success 👍');
                setLoading(false);
                setUpdator(Math.random());

              }
              else if (response.status === false) {
                toast.dismiss()
                toast.error('error ! Your last transaction is failed.');
                setLoading(false);
                setUpdator(Math.random());

              }
              else {
                toast.dismiss()
                toast.error('error ! something went wrong.');
                setLoading(false);
                setUpdator(Math.random());

              }
            }
          }, 5000);
        }
        catch (err) {
          console.log(err.message)
          toast.dismiss()
          toast.error(err.reason ? err.reason : err.message);
          setLoading(false);

        }
      }
      else {
        toast.dismiss()
        toast.error('Please select Binance Mainnet Network !');
        setLoading(false);

      }

    }
    else {
      toast.dismiss()
      toast.error('Please Connect Wallet!');
      setLoading(false);

    }
  }


  const handleSubmit = async () => {
    if (address) {
      if (chain && parseInt(chain.id) === parseInt(DEFAULT_CHAIN)) {
        try {

          setLoading(true);

          let presaleContract = getContract(presaleAbi, PRESALE_ADDRESS, signer);
          let amount1 = ethers.utils.parseEther(amount.toString()).toString();
          let refAddr = refAddress ? refAddress : '0x0000000000000000000000000000000000000000';
          let valuePass = BUY_CURRENCY[selectedId].address === '0x0000000000000000000000000000000000000000' ? amount1 : 0;
          let tx = await presaleContract.buyToken(BUY_CURRENCY[selectedId].address, amount1, refAddr, { 'from': address, value: valuePass });
          toast.loading('Waiting for confirmation')

          var interval = setInterval(async function () {
            let web3 = getWeb3();
            var response = await web3.eth.getTransactionReceipt(tx.hash);
            if (response != null) {
              clearInterval(interval)
              if (response.status === true) {
                toast.dismiss()
                toast.success('success ! your last transaction is success 👍');
                setLoading(false);
                setUpdator(Math.random());

              }
              else if (response.status === false) {
                toast.dismiss()
                toast.error('error ! Your last transaction is failed.');
                setLoading(false);
                setUpdator(Math.random());

              }
              else {
                toast.dismiss()
                toast.error('error ! something went wrong.');
                setLoading(false);
                setUpdator(Math.random());

              }
            }
          }, 5000);
        }
        catch (err) {
          console.log(err)
          toast.dismiss()
          toast.error(err.reason ? err.reason : err.message);
          setLoading(false);

        }
      }
      else {
        toast.dismiss()
        toast.error('Please select Binance Mainnet Network !');
        setLoading(false);

      }

    }
    else {
      toast.dismiss()
      toast.error('Please Connect Wallet!');
      setLoading(false);

    }
  }


  const handleClaim = async () => {
    if (address) {
      if (parseInt(chain.id) === parseInt(DEFAULT_CHAIN)) {
        try {

          setCloading(true);

          let presaleContract = getContract(presaleAbi, PRESALE_ADDRESS, signer);
          let tx = await presaleContract.claim({ 'from': address });
          toast.loading('Waiting for confirmation')

          var interval = setInterval(async function () {
            let web3 = getWeb3();
            var response = await web3.eth.getTransactionReceipt(tx.hash);
            if (response != null) {
              clearInterval(interval)
              if (response.status === true) {
                toast.dismiss()
                toast.success('success ! your last transaction is success 👍');
                setCloading(false);
                setUpdator(Math.random());

              }
              else if (response.status === false) {
                toast.dismiss()
                toast.error('error ! Your last transaction is failed.');
                setCloading(false);
                setUpdator(Math.random());

              }
              else {
                toast.dismiss()
                toast.error('error ! something went wrong.');
                setCloading(false);
                setUpdator(Math.random());

              }
            }
          }, 5000);
        }
        catch (err) {
          console.log(err.message)
          toast.dismiss()
          toast.error(err.reason ? err.reason : err.message);
          setCloading(false);

        }
      }
      else {
        toast.dismiss()
        toast.error('Please select Binance Mainnet Network !');
        setCloading(false);

      }

    }
    else {
      toast.dismiss()
      toast.error('Please Connect Wallet!');
      setCloading(false);

    }
  }

  return (
    <div id="popup" className="popup" style={{ fontFamily: 'outfit', display: show ? 'block' : 'none' }}>
      <div className="popup-content" style={{ borderRadius: '10px' }}>
        <span className="closeBtn" onClick={() => setShow(false)}>&times;</span>

        <div className="col-lg-4" style={{ width: '100%' }}>

          <img src="assets/images/logo/logo.png" style={{ marginTop: '6px' }} />

          <p style={{ textAlign: 'justify', marginTop: '15px' }}>Simply choose the method that best suits your needs.</p>
          <div className='d-flex justify-content-between align-items-center'>
            <ul className="common-tab nav nav-pills" id="pills-tabFilter" role="tablist" style={{ marginTop: '20px', marginBottom: '10px' }}>
              <li className="nav-item" role="presentation">
                <button className="nav-link active" id="pills-sellTwo-tab" data-bs-toggle="pill" data-bs-target="#pills-sellTwo" type="button" role="tab" aria-controls="pills-sellTwo" aria-selected="true" style={{ padding: '3px 10px !important', fontSize: '12px' }}>CRIPTO</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="pills-buyTwo-tab" data-bs-toggle="pill" data-bs-target="#pills-buyTwo" type="button" role="tab" aria-controls="pills-buyTwo" aria-selected="false" tabIndex="-1" style={{ padding: '3px 10px !important', fontSize: '12px' }}>Pix</button>
              </li>
            </ul>
            <div>
              <ConnectButton className="btn btn-outline-light d-lg-block startpage_bt_single connect-btn" />
            </div>
          </div>

          <div className="tab-content" id="pills-tabContentFilter">
            <div className="tab-pane fade" id="pills-rentTwo" role="tabpanel" aria-labelledby="pills-rentTwo-tab" tabIndex="0">
              {/* Content for Rent tab */}
            </div>
            <div className="tab-pane fade" id="pills-buyTwo" role="tabpanel" aria-labelledby="pills-buyTwo-tab" tabIndex="0">
              <div className="filter" style={{ backgroundColor: '#ffffff8c' }}>
                <form action="#">
                  <div className="row gy-sm-4 gy-3">
                    {/* Form content for Buy with Pix */}
                  </div>
                </form>
              </div>
            </div>
            <div className="tab-pane fade active show" id="pills-sellTwo" role="tabpanel" aria-labelledby="pills-sellTwo-tab" tabIndex="0">
              <div className="filter" style={{ backgroundColor: '#ffffff8c', marginBottom: '20px', padding: '10px 20px' }}>
                <li className="billing-list__item flx-between" style={{ marginBottom: '2px' }}>
                  <span className="text text-poppins fw-semibold text-heading">Referral link ({stats.levelRate ? parseFloat(stats.levelRate / 100) : 0}%)</span>
                </li>
                <div style={{ display: 'inline-flex', width: '100%' }}>
                  <div style={{ width: '90%' }}>
                    <input type="text" value={address ? `${window.location.href}?ref=${address}` : 'Please connect wallet'} className="common-input" style={{ padding: '5px 2px', fontSize: '13px' }} />
                  </div>
                  <div style={{ width: '10%', backgroundColor: '#987646', marginLeft: '1%', borderRadius: '4px' }}>
                    <CopyToClipboard text={address ? `${window.location.href}?ref=${address}` : 'Please connect wallet'} onCopy={() => {
                      setRefcopy(true);
                      setTimeout(() => {
                        setRefcopy(false);
                      }, 2000)
                    }}>
                      <button style={{ color: 'white', width: '100%', height: '100%' }}>
                        {!refcopy ? (

                          <svg viewBox="0 0 24 24" width="20" height="20" stroke="#fff" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>

                        ) : (
                          <svg viewBox="0 0 24 24" width="20" height="20" stroke="#fff" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect></svg>
                        )}
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>

              <div className="filter" style={{ backgroundColor: '#ffffff8c', padding: '20px!important' }}>
                <li className="billing-list__item flx-between" style={{ marginBottom: '2px' }}>
                  <span className="text text-poppins fw-semibold text-heading" style={{ fontWeight: '400!important' }}>Sale Price:</span>
                  <span className="amount fw-semibold text-heading text-poppins" style={{ fontWeight: '400!important' }}>${stats.rate ? formatPrice(stats.rate) : 0}</span>
                </li>
                <li className="billing-list__item flx-between">
                  <span className="text text-poppins fw-semibold text-heading" style={{ fontWeight: '400!important' }}>Launch Price:</span>
                  <span className="amount fw-semibold text-heading text-poppins" style={{ fontWeight: '400!important' }}>$0.1</span>
                </li>
                <form action="#">
                  <div className="row gy-sm-4 gy-3">
                    <div className="col-lg-12" style={{ marginTop: '10px!important' }}>
                      <div className="select-has-icon icon-black" style={{ marginTop: '10px!important' }}>
                        <select onChange={(e) => setSelectedId(e.target.value)} className="select common-input" style={{ padding: '10px 24px', fontSize: '15px' }}>
                          {BUY_CURRENCY && BUY_CURRENCY.length > 0 && BUY_CURRENCY.map((items, key) => (
                            <option value={key} selected={key === selectedId}>{items.symbol} (bep20)</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-12" style={{ marginTop: '10px!important' }}>
                      <input type="text" value={amount} onChange={(e) => handleChangeAmount(e)} className="common-input" placeholder="Amount" style={{ padding: '10px 24px', fontSize: '15px' }} />
                      <div className='d-flex justify-content-between mt-1'>
                        <div>Balance : {accStats.tokenBal ? formatPrice(accStats.tokenBal, 5) : 0} {selectedData?.symbol}</div>
                        <div>~${formatPrice(usdValue)}</div>
                      </div>
                    </div>
                    <li className="flx-between" style={{ marginBottom: '0px!important' }}>
                      <span className="text text-poppins fw-semibold text-heading">Receive on RAC:</span>
                      <span className="amount fw-semibold text-heading text-poppins">{formatPrice(tokenAmount)}</span>
                    </li>
                    <div className="col-lg-12 mt-2" style={{ marginTop: '0px!important' }}>
                      {address ? (
                        parseFloat(accStats.isApproved) >= amount ? (
                          <button onClick={() => handleSubmit()} type="button" disabled={!stats.saleStatus || loading} className="btn btn-main w-100" style={{ padding: '12px 10px' }}>
                            {loading ? 'Loading...' : 'Buy Now'}
                          </button>
                        ) : (
                          <button onClick={() => handleApprove()} disabled={!stats.saleStatus || loading} type="button" className="btn btn-main w-100" style={{ padding: '12px 10px' }}>
                            {loading ? 'Loading...' : 'Approve'}
                          </button>

                        )
                      ) : (
                        <ConnectButton className="btn btn-main w-100 connect-btn-pad" />
                      )}
                    </div>
                    <li className="flx-between mt-4" style={{ marginBottom: '0px!important' }}>
                      <span className="text text-poppins font-200 text-heading">Total remaining to vest:</span>
                      <span className="amount font-200 text-heading text-poppins">{formatPrice(accStats.purchasedOf - accStats.claimedOf)}</span>
                    </li>
                    <li className="flx-between mt-0" style={{ marginBottom: '0px!important' }}>
                      <span className="text text-poppins font-200 text-heading">Available to claim on:</span>
                      <span className="amount font-200 text-heading text-poppins">{formatPrice(accStats.userAvalibleClaim)}</span>
                    </li>
                    <div className="col-lg-12 mt-2" style={{ marginTop: '0px!important' }}>
                      {address ? (

                        <button onClick={() => handleClaim()} type="button" disabled={cloading || accStats.userAvalibleClaim <= 0} className="btn btn-main w-100" style={{ padding: '12px 10px', marginTop: '5px' }}>
                          {cloading ? 'Loading...' : 'Claim Tokens'}
                        </button>

                      ) : (
                        <ConnectButton className="btn btn-main w-100 connect-btn-pad" />
                      )}

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: '15px', textAlign: 'center' }}>
          <img src="assets/images/partners/zome.png" style={{ width: '10px', opacity: '0.8', marginLeft: '5px' }} />
          <img src="assets/images/partners/cent.png" style={{ width: '18px', opacity: '0.8', marginLeft: '5px' }} />
          <img src="assets/images/partners/era.png" style={{ width: '24px', marginLeft: '5px' }} />
          <img src="assets/images/partners/remax.png" style={{ width: '20px', marginLeft: '5px' }} />
          <img src="assets/images/partners/mcdonads.png" style={{ width: '38px', marginLeft: '5px' }} />
          <img src="assets/images/partners/burguer.png" style={{ width: '24px', marginLeft: '5px' }} />
        </div>

      </div>
    </div>
  );
}

export default BuyModal;
