import React from 'react'

export default function MobileMenu() {
  return (
    <div className="mobile-menu d-lg-none d-block" style={{ fontFamily: 'Outfit' }}>
      <button type="button" className="close-button"> x </button>
      <div className="mobile-menu__inner">
        <a href="index.html" className="mobile-menu__logo">
          <img src="assets/images/logo/logo.png" alt="Logo" />
        </a>

        <div className="mobile-menu__menu">
          <ul className="nav-menu flx-align nav-menu--mobile">
            <li className="nav-menu__item">
              <a href="#mission" className="nav-menu__link">Mission</a>
            </li>
            <li className="nav-menu__item">
              <a href="#partners" className="nav-menu__link">Partners</a>
            </li>
            <li className="nav-menu__item">
              <a href="#token" className="nav-menu__link">Token</a>
            </li>
            <li className="nav-menu__item">
              <a href="#token" className="nav-menu__link">RCP Documentation</a>
            </li>
            <li className="nav-menu__item">
              <a href="#token" className="nav-menu__link">RCP Login</a>
            </li>
            <li className="nav-menu__item">
              <a href="#team" className="nav-menu__link">Team</a>
            </li>
            <li className="nav-menu__item">
              <a href="#contact" className="nav-menu__link">Contact</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
