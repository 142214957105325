import React from 'react';

export default function Section1({setShow}) {
  return (
    <section className="banner" style={{ padding: '75px 0 50px' }}>
      <div className="container container-two" style={{ paddingRight: 'calc(var(--bs-gutter-x)* 2.5)', paddingLeft: 'calc(var(--bs-gutter-x)* 2.5)' }}>
        <div className="position-relative">
          <div className="row">
            <div className="col-lg-6">
              <div className="banner-inner position-relative">
                <div className="banner-content startpage">
                  <img src="assets/images/logo/logo.png" className="startpage_logo" alt="Logo" />
                  <h1 className="banner-content__title startpage_1_text" style={{ fontFamily: 'outfit', fontSize: '40px', fontWeight: 200 }}>
                    Transforming Properties<br />
                    <span style={{ fontSize: '40px', fontWeight: 700, color: 'black' }} className="startpage_2_text">
                      INTO DIGITAL INVESTMENT
                    </span>
                  </h1>
                  <div style={{ display: 'inline-flex' }} className="startpage_bt">
                    <a href="https://real-asset-capitals.gitbook.io/whitepaper-or-real-asset-capitals/project-explanation/real-asset-capitals/video-explanation"
                      target="_blank" className="btn btn-outline-light d-lg-block startpage_bt_single sale-btn"
                      style={{ padding: '10px', width: '140px', fontFamily: 'outfit' }}>
                      <span>Whitepaper</span>
                    </a>
                    <a className="btn btn-outline-light d-lg-block startpage_bt_single sale-btn"
                      style={{ padding: '10px', width: '140px', fontFamily: 'outfit', marginLeft: '5px' }}
                      id="openPopupBtn" onClick={()=>setShow(true)}>private sale</a>
                    <a href="#" className="btn btn-outline-light d-lg-block startpage_bt_single sale-btn"
                      style={{ padding: '10px', width: '140px', fontFamily: 'outfit', marginLeft: '5px' }}>
                      RCP PLATFORM
                    </a>
                  </div>

                  <img src="assets/images/thumbs/banner-mobile.png" alt="Image4"
                    style={{ display: 'none', marginTop: '38px', zIndex: 2, width: '80%', animation: 'rotationScale 5s linear infinite' }}
                    className="img_ourmission_mobile" />

                  <div className="startpage_partners_mobile" style={{ marginTop: '38px', display: 'none' }}>
                    <div>
                      <img src="assets/images/partners/zome.png" style={{ width: '6%', opacity: 0.8 }} alt="Partner" />

                      <img src="assets/images/partners/cent.png"
                        style={{ width: '7%', opacity: 0.8, marginLeft: '10px' }} alt="Partner" />

                      <img src="assets/images/partners/era.png"
                        style={{ width: '8%', opacity: 0.8, marginLeft: '10px' }} alt="Partner" />

                      <img src="assets/images/partners/remax.png"
                        style={{ width: '7%', opacity: 0.8, marginLeft: '10px' }} alt="Partner" />

                      <img src="assets/images/partners/mcdonads.png"
                        style={{ width: '10%', opacity: 0.8, marginLeft: '10px' }} alt="Partner" />

                      <img src="assets/images/partners/burguer.png"
                        style={{ width: '8%', opacity: 0.8, marginLeft: '10px' }} alt="Partner" />

                    </div>
                  </div>

                  <p className="banner-content__desc font-18 startpage_3_text"
                    style={{ fontFamily: 'outfit', marginTop: '10%' }}>
                    Real Asset Capitals is a company dedicated to providing real asset investment solutions based on tokenization!
                  </p>
                  <div className="startpage_partners_desktop" style={{ marginTop: '38px' }}>
                    <div>
                      <img src="assets/images/partners/zome.png" style={{ width: '6%', opacity: 0.8 }} alt="Partner" />

                      <img src="assets/images/partners/cent.png"
                        style={{ width: '7%', opacity: 0.8, marginLeft: '10px' }} alt="Partner" />

                      <img src="assets/images/partners/era.png"
                        style={{ width: '8%', opacity: 0.8, marginLeft: '10px' }} alt="Partner" />

                      <img src="assets/images/partners/remax.png"
                        style={{ width: '7%', opacity: 0.8, marginLeft: '10px' }} alt="Partner" />

                      <img src="assets/images/partners/mcdonads.png"
                        style={{ width: '10%', opacity: 0.8, marginLeft: '10px' }} alt="Partner" />

                      <img src="assets/images/partners/burguer.png"
                        style={{ width: '8%', opacity: 0.8, marginLeft: '10px' }} alt="Partner" />

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="tab-content sinvestment" id="pills-tabContent" style={{ fontFamily: 'outfit', marginTop: '30px' }}>
              <div className="tab-pane fade show active" id="pills-rent" role="tabpanel" aria-labelledby="pills-rent-tab" tabIndex={0}>

                <div className="filter"
                  style={{ padding: '10px!important', background: 'linear-gradient(to right, rgb(255 255 255 / 9%), rgb(255 255 255 / 22%) , rgb(255 255 255 / 83%) , rgb(255 255 255 / 91%) , rgb(255 255 255) )', boxShadow: '-1px 4px 11px -2px rgb(16 16 16 / 53%), 0px 4.8px 29.4px -6px rgba(19, 16, 34, 0.1)' }}>
                  <form action="#">
                    <div className="row gy-sm-4 gy-3">
                      <div className="col-lg-3 col-sm-6 col-xs-6">
                        <input type="text" className="common-input" placeholder="Soon"
                          style={{ border: '1px solid #a88c6673', padding: '10px 24px' }} />
                      </div>
                      <div className="col-lg-3 col-sm-6 col-xs-6">
                        <div className="select-has-icon icon-black">
                          <select className="select common-input"
                            style={{ border: '1px solid #a88c6673', padding: '10px 24px' }}>
                            <option value="1" disabled>Soon</option>
                            <option value="1">Soon</option>
                            <option value="1">Soon</option>
                            <option value="1">Soon</option>
                            <option value="1">Soon</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6 col-xs-6">
                        <div className="select-has-icon icon-black">
                          <select className="select common-input"
                            style={{ border: '1px solid #a88c6673', padding: '10px 24px' }}>
                            <option value="1" disabled>Soon</option>
                            <option value="1">Soon</option>
                            <option value="1">Soon</option>
                            <option value="1">Soon</option>
                            <option value="1">Soon</option>
                            <option value="1">Soon</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6 col-xs-6">
                        <button type="submit" className="btn btn-main w-100"
                          style={{ padding: '13px 24px' }}>Soon</button>
                      </div>
                    </div>
                  </form>
                </div>

              </div>

              <div className="tab-pane fade" id="pills-buy" role="tabpanel" aria-labelledby="pills-buy-tab" tabIndex={0}>

                <div className="filter" style={{ padding: '10px!important' }}>
                  <form action="#">
                    <div className="row gy-sm-4 gy-3">
                      <div className="col-lg-3 col-sm-6 col-xs-6">
                        <input type="text" className="common-input" placeholder="Soon"
                          style={{ border: '1px solid #a88c66' }} />
                      </div>
                      <div className="col-lg-3 col-sm-6 col-xs-6">
                        <div className="select-has-icon icon-black">
                          <select className="select common-input"
                            style={{ border: '1px solid #a88c66' }}>
                            <option value="1" disabled>Soon</option>
                            <option value="1">Soon</option>
                            <option value="1">Soon</option>
                            <option value="1">Soon</option>
                            <option value="1">Soon</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6 col-xs-6">
                        <div className="select-has-icon icon-black">
                          <select className="select common-input">
                            <option value="1" disabled>Soon</option>
                            <option value="1">Soon</option>
                            <option value="1">Soon</option>
                            <option value="1">Soon</option>
                            <option value="1">Soon</option>
                            <option value="1">Soon</option>
                            <option value="1">Soon</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6 col-xs-6">
                        <button type="submit" className="btn btn-main w-100">Soon</button>
                      </div>
                    </div>
                  </form>
                </div>

              </div>

              <div className="tab-pane fade" id="pills-sell" role="tabpanel" aria-labelledby="pills-sell-tab" tabIndex={0}>

                <div className="filter">
                  <form action="#">
                    <div className="row gy-sm-4 gy-3">
                      <div className="col-lg-3 col-sm-6 col-xs-6">
                        <input type="text" className="common-input" placeholder="Enter Keyword"
                          style={{ border: '1px solid #a88c66' }} />
                      </div>
                      <div className="col-lg-3 col-sm-6 col-xs-6">
                        <div className="select-has-icon icon-black">
                          <select className="select common-input">
                            <option value="1" disabled>Soon</option>
                            <option value="1">Soon</option>
                            <option value="1">Soon</option>
                            <option value="1">Soon</option>
                            <option value="1">Soon</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6 col-xs-6">
                        <div className="select-has-icon icon-black">
                          <select className="select common-input">
                            <option value="1" disabled>Soon</option>
                            <option value="1">Soon</option>
                            <option value="1">Soon</option>
                            <option value="1">Soon</option>
                            <option value="1">Soon</option>
                            <option value="1">Soon</option>
                            <option value="1">Soon</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6 col-xs-6">
                        <button type="submit" className="btn btn-main w-100">Soon</button>
                      </div>
                    </div>
                  </form>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}