import React from 'react'

export default function PreLoader() {
  return (
    <>
    <div className="preloader" id="loader">
      <img
        src="/assets/images/logo/logo1.png"
        style={{
          opacity: 0.9,
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '200px',
          top: '45%',
          position: 'relative',
        }}
        alt="Logo"
      />
      <div id="progress-container">
        <div id="progress-bar"></div>
        <div id="progress-text" style={{ zIndex: 10000, fontFamily: 'Outfit' }}>
          0%
        </div>
      </div>
    </div>
        <div class="progress-wrap" style={{"right": "43px" , "bottom": "115px"}}>
        <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
    </div>
    </>
  )
}
